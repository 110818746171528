import React from "react";
import RegisterForm from "../../containers/BpRegister/CustomerForm/CustomerFormSteps";
import Layout from "../../components/Layout/Layout";
import { isLoggedIn, getUserType } from "../../common/utils/cookie-utils";
import withErrorHandler from "../../hoc/withErrorHandler/withErrorHandler";
import { navigate } from "gatsby";

const Signup = () => {
  const isLogged = isLoggedIn();
  const userType = getUserType();
  if (!isLogged)
    return (
      <Layout customTitle="Signup Form | ">
        <div>
          <RegisterForm />
        </div>
      </Layout>
    );
  else if (userType != undefined) {
    if (userType == 1) {
      navigate("/app/dashboard/bp");
    } else {
      navigate("/app/dashboard");
    }
  }
  return null;
};

export default Signup;
