import React, { Fragment, useEffect, useState } from "react";
import { navigate } from "gatsby";
import logo from "../../../images/logoV1.png";
import fb from "../../../images/socialMedia/facebook-square-brands.svg";
import insta from "../../../images/socialMedia/Image 1@2x.png";
import linkedIn from "../../../images/socialMedia/linkedin-brands.svg";
import LogOut from "../../../common/components/Logout/Logout";
import { SuccessDes } from "../../../common/components/PopUp/CommonSvgs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/pro-solid-svg-icons";
import { useFormikContext } from "formik";
import { useFormikWizard } from "formik-wizard";
import search_icon from "../../../images/icons/search_icon.svg";
import searchIconSolid from "../../../images/icons/searchIconSolid.svg";
import searchDelete from "../../../images/icons/searchDelete.svg";

const ThankYouPage = ({ consentFlow, goToHome, dcrmBpOnboarding }) => {
  const [loggedOut, setLoggedOut] = useState(false);
  const [showOption, setShowOption] = useState(true);

  const { values: wizardValues } = useFormikWizard();

  return (
    <div
      className={
        "justify-around min-h-screen md:min-h-70v" +
        " flex flex-col   w-full max-w-lg bg-white rounded-xl mx-auto "
      }
    >
      <div className="justify-around">
        {!dcrmBpOnboarding && (
          <div className="w-full mx-auto bg-white">
            <a target="_blank" href="https://fincity.com">
              <img
                src={logo}
                className="mx-4 py-2 w-37 block mx-auto cursor-pointer"
                alt="Fincity"
              />
            </a>
          </div>
        )}
        <div
          className="w-full mx-auto bg-white text-center"
          style={{ width: "fit-content" }}
        >
          <div className="mx-auto" style={{ width: "fit-content" }}>
            <SuccessDes />
          </div>
          <p
            className="mx-auto font-medium self-start"
            style={{ color: "#064E55 " }}
          >
            Thanks for Your Registration.
            <br />
          </p>
        </div>
        {goToHome && (
          <div
            className="submitButton w-44 text-center font-light py-10 h-12 pt-12 cursor-pointer"
            onClick={() => location.reload(true)}
          >
            <span className="mt-1 block">Go To Home</span>
          </div>
        )}
      </div>
      <div>
        <div className="self-end place-self-end">
          <p className={"text-center mt-28 mb-2"}>follow us on</p>

          <div
            className={
              (consentFlow ? " space-x-5 mt-4" : " space-x-8") +
              " flex mx-auto text-center content-center "
            }
            style={{
              width: "fit-content",
            }}
          >
            <a href={"https://www.facebook.com/fincityindia"} target="_blank">
              <img src={fb} className={"cursor-pointer"} alt="facebook" />
            </a>
            <a href={"https://www.instagram.com/fincityindia"} target="_blank">
              <img className="w-6 cursor-pointer" src={insta} alt="instagram" />
            </a>
            <a
              href={"https://www.linkedin.com/company/fincityindia"}
              target="_blank"
            >
              <img src={linkedIn} className={"cursor-pointer"} alt="linkedIn" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYouPage;
