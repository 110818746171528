import React from "react";
import { object, array, string, number, bool } from "yup";
import Login from "../CustomerForm/CustomerFormComponent/Login";
import GlobalOtp from "../../../common/components/GlobalOtpComp/GlobalOtpComp";
import ThankYouPage from "../ThankYouPage/ThankYouPage";
import { bpSendOtp, bpVerifyOtp } from "../../../common/constants/api";
let allValues = { step: "RequirementForm" };
export const getAllValues = () => allValues;

const Steps = [
  {
    id: "login",
    component: Login,
    initialValues: {
      name: "",
      firmName: "",
      mobile: "",
      permission: true,
    },
    validationSchema: object().shape({
      name: string().required("Required"),
      firmName: string().required("Required"),
      mobile: string()
        .required("Required")
        .matches(/([6-9]\d{9})/, "Mobile should be 10 digit number"),
      permission: bool()
        .test(
          "permission",
          "Must allow to access credit information ",
          value => value === true
        )
        .required("Must allow to access credit information"),
    }),
    onAction: async (sectionValues, formValues) => {
      bpSendOtp(sectionValues?.mobile)
        .then(res => {})
        .catch(err => {
          throw new Error();
        });
    },
  },
  {
    id: "otp",
    component: () => (
      <GlobalOtp
        itsWalkinForm={false}
        itsBpSignup={true}
        consumerSendOtp={bpSendOtp}
      />
    ),
    initialValues: {
      otp: "",
      oppId: "",
      skipOtp: false,
    },
    onAction: async (sectionValues, formValues) => {
      await bpVerifyOtp({
        businessPartnerName: formValues?.login?.name,
        phoneNumber: formValues?.login?.mobile,
        otp: sectionValues?.otp,
        firmName: formValues?.login?.firmName,
      })
        .then(async res => {
          sectionValues.oppId = res;
        })
        .catch(err => {
          throw new Error();
        });
    },
  },
  {
    id: "thankYou",
    component: () => <ThankYouPage goToHome dcrmBpOnboarding />,
  },
];
export default Steps;
