import React, { useEffect } from "react";
import Stepper from "../../../common/components/Stepper/Stepper";
import MobileStepper from "../../../common/components/MobileStepper/MobileStepper";
import Steps from "./Steps";
import FormikWizard from "formik-wizard";
import backBtn_icon from "../../../images/icons/backBtn_icon.svg";
import User from "../../../images/icons/ConsumerFormIcons/userConsumer.svg";
import Work from "../../../images/icons/ConsumerFormIcons/workConsumer.svg";
import Requirement from "../../../images/icons/ConsumerFormIcons/homeConsumer.svg";
import Announce from "../../../images/icons/ConsumerFormIcons/announceConsumer.svg";
import withErrorHandler from "../../../hoc/withErrorHandler/withErrorHandler";
import Header from "../../../common/components/Header/Header";

let stepsText = [
  "Your Information",
  "Your Occupation",
  "Your Requirement",
  "How did you hear of us?",
];
const icons = {
  1: <img src={User} alt="" className="h-5" />,
  2: <img src={Work} alt="" className="h-5" />,
  3: <img src={Requirement} alt="" className="h-5" />,
  4: <img src={Announce} alt="" className="h-5" />,
};

function FormWrapper({ children, canGoBack, wizard, steps }) {
  const handleGoBack = () => {
    if (!canGoBack) return;
    let currStepIndex = wizard.steps.indexOf(wizard.step);
    wizard.push(steps[currStepIndex - 1]);
  };
  const clickFunc = () => {
    let currStepIndex = wizard.steps.indexOf(wizard.step);
    wizard.push(steps[currStepIndex]);
  };
  if (!canGoBack) return children;
  else if (wizard.step.id === "otp")
    return (
      <div className=" w-full h-full min-h-screen overflow-auto scrolling-auto ">
        <Header bgWhite noAccountPls />
        <div className="w-full md:min-w-50v max-w-lg mx-auto py-5 px-5 ">
          <div
            className="pl-5 pt-16  flex flex-row items-center space-x-3 "
            style={{ width: "max-content" }}
          >
            <img src={backBtn_icon} alt="< back" onClick={handleGoBack} />
            <p className="text-black-400 text-2xl font-semibold ">
              OTP Verification
            </p>
          </div>
          {children}
        </div>
      </div>
    );
  else if (wizard.step.id === "thankYou")
    return <div className="md:pt-20">{children}</div>;
  else
    return (
      <React.Fragment>
        <div className="bg-grey-950 lg:block hidden relative lg:h-full h-screen overflow-hidden font-roboto text-sm font-normal m-0 p-0">
          <div className=" lg:min-h-screen flex pt-17 md:pt-0">
            <div className="lg:mx-10  w-full lg:w-12/12 px-2 bg-white min-h-screen lg:py-2">
              <div className="w-full h-full lg:min-h-screen lg:overflow-auto lg:scrolling-auto ">
                <Header bgWhite noAccountPls />
                <div className="overflow-x-auto ">
                  <Stepper
                    activeStep={wizard.steps.indexOf(wizard.step) - 2}
                    stepsText={stepsText}
                    stepIcons={icons}
                  />
                </div>

                <div className="w-full flex">
                  <div
                    className={`w-10 mx-2 ml-24 ${
                      !canGoBack || wizard.step.id !== "CustomerInformation"
                        ? "cursor-pointer "
                        : "opacity-25 cursor-not-allowed"
                    }`}
                  >
                    <img
                      onClick={
                        !canGoBack || wizard.step.id !== "CustomerInformation"
                          ? handleGoBack
                          : ""
                      }
                      src={backBtn_icon}
                      alt=""
                    />
                  </div>
                </div>
                {children}
              </div>
            </div>
          </div>
        </div>
        <div className="lg:hidden block w-full">
          <div className="w-full h-full min-h-screen overflow-auto scrolling-auto ">
            <Header bgWhite noAccountPls />
            <span
              className={`w-5 ${
                !canGoBack || wizard.step.id !== "CustomerInformation"
                  ? "cursor-pointer"
                  : " opacity-25 cursor-not-allowed"
              }`}
            >
              <img
                onClick={
                  !canGoBack || wizard.step.id !== "CustomerInformation"
                    ? handleGoBack
                    : ""
                }
                src={backBtn_icon}
                alt=""
                className="pl-5 mt-2 "
              />
            </span>

            <MobileStepper
              activeStep={wizard.steps.indexOf(wizard.step) - 2}
              stepsText={stepsText}
              stepIcons={icons}
              getContent={children}
              clickFunc={clickFunc}
            />
          </div>
        </div>
      </React.Fragment>
    );
}
const CustomerFormSteps = () => {
  const handleSubmit = React.useCallback(values => {}, []);
  return (
    <FormikWizard steps={Steps} onSubmit={handleSubmit} render={FormWrapper} />
  );
};
export default CustomerFormSteps;
