import React, { useState, useEffect } from "react";
import { useFormikContext } from "formik";
import * as _ from "lodash";
import CommonCheckbox from "../../../../common/components/CommonCheckBox/CommonCheckbox";
import CommonInput from "../../../../common/components/CommonComponent/CommonInput";
import { ThrowError } from "../../../../common/constants/ThrowError";
import { validate as validatePhoneNumber } from "../../../../common/constants/PhoneNumberValidation";
import withErrorHandler from "../../../../hoc/withErrorHandler/withErrorHandler";
import useWindowResize from "../../../../hooks/useWindowResize";
import headerimg2x from "../../../../images/walkInLogin/headerimg@2x.png";
import headerimg from "../../../../images/walkInLogin/headerimg.png";
import LogoFincity from "../../../../images/walkInLogin/fincityCompressed.svg";
import smiley from "../../../../images/walkInLogin/smiley.png";
import smiley2x from "../../../../images/walkInLogin/smiley2x.png";
import Whatsapp from "../../../../images/whatsapp_logo.svg";
import headerCons from "../../../../images/consumer/headerCons.svg";
import SvgComponent from "../../../../common/components/SvgComponent/SvgComponent";
const Login = () => {
  const [windowWidth, windowHeight] = useWindowResize();
  const [params, setParams] = useState("");
  const [walkInToken, setWalkInToken] = useState("");
  const whatsappIconStyle = {
    color: "#39d365",
    width: "15px",
    height: "17px",
  };
  const {
    values,
    handleChange,
    errors,
    touched,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    validateForm,
  } = useFormikContext();
  const inputLength = values?.mobile?.length;
  useEffect(() => {
    validateForm();
  }, []);
  useEffect(() => {
    setParams(new URL(window.location.href).searchParams);
  }, []);
  useEffect(() => {}, [params]);
  useEffect(() => {}, [walkInToken]);

  return (
    <div className=" flex flex-col justify-between w-full  max-w-lg mx-auto py-5 px-4">
      <div>
        <img src={headerimg} srcSet={headerimg2x} alt="headerImage" />
      </div>
      <div className="mx-auto mt-6">
        <img src={headerCons} alt="FincityLogo" />
      </div>
      <div className="flex flex-row mx-auto items-center content-center mt-2 ">
        <span className="block text-lg font-medium text-black-400 ">
          Let’s get to know you
        </span>
        <span>
          <img className="block w-9 h-9" src={smiley} srcSet={smiley2x} />
        </span>
      </div>
      <div className="my-4">
        <CommonInput
          name="name"
          id="name"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.name}
          stylesContainer={{
            height: "52px   ",
            maxWidth: "100%",
          }}
          legendName="Name"
          error={touched?.name && errors?.name}
          errorMsg={errors?.name}
          touched={touched?.name}
        />
      </div>
      <div className="my-4">
        <CommonInput
          name="firmName"
          id="firmName"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values?.firmName}
          stylesContainer={{
            height: "52px",
            maxWidth: "100%",
          }}
          legendName="Firm Name"
          error={touched?.firmName && errors?.firmName}
          errorMsg={errors?.firmName}
          touched={touched?.firmName}
        />
      </div>
      <div className="my-4">
        <div
          style={{ marginTop: "17px" }}
          className="absolute text-grey-50 z-10 font-normal text-sm pt-px text-md ml-4"
        >
          +91
        </div>
        <div>
          <CommonInput
            name="mobile"
            id="mobile"
            maxLength={10}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.mobile}
            stylesContainer={{
              height: "52px",
              maxWidth: "100%",
            }}
            inputStyle={{
              paddingLeft: "45px",
              letterSpacing: "1px",
            }}
            onBlurStyle={{
              paddingLeft: "20px",
            }}
            legendStyle={{
              paddingLeft: errors?.mobile ? "20px" : "20px",
            }}
            legendName="Mobile Number"
            error={touched?.mobile && errors?.mobile}
            errorMsg={errors?.mobile}
            className={`pt-24`}
            touched={touched?.mobile}
            // disabled
            onKeyPress={e => {
              validatePhoneNumber(e, inputLength);
            }}
          />
        </div>
      </div>
      <div className="relative h-full w-full inline-flex flex-row mt-1 items-center ">
        <div
          className="absolute w-1/5 "
          style={{
            height: "100%",
            backgroundColor: "#FD80231C",
          }}
        ></div>
        <CommonCheckbox
          containerStyles={{
            position: "absolute",
            ...(windowWidth > 400
              ? { left: "5%" }
              : windowWidth > 300
              ? { left: "1%" }
              : { left: "-2%" }),
          }}
          inputStyles={{
            width: "24px",
            height: "24px",
            marginTop: "-10px",
            marginLeft: "12px",
            ...(!values?.permission
              ? {
                  border: "2px solid #FD8023",
                  borderRadius: "4px",
                }
              : {
                  borderRadius: "4px",
                  borderWidth: "2px",
                }),
          }}
          checked={values?.permission || true}
          name="permission"
          value={values.permission}
          onChange={e => {
            setFieldValue("permission", e.target.checked, true);
            setFieldTouched("permission", true, false);
          }}
          error={errors?.permission && touched?.permission}
          onBlur={handleBlur}
        />
        <div className="w-4/5 h-full bg-navy-600 p-3 ml-auto">
          <p className="text-xs block mt-px text-grey-2232 font-roboto font-normal lg:tracking-wide break-normal">
            I agree to be contacted by Fincity Team via
            <SvgComponent
              img={Whatsapp}
              className="inline-block mr-1 ml-1"
              style={whatsappIconStyle}
            />
            WhatsApp, SMS, phone, email etc.
          </p>
        </div>
      </div>
      <button
        id="submit"
        className={"submitButton w-full "}
        type="submit"
        disabled={!_.isEmpty(errors)}
      >
        Get OTP
      </button>
      <ThrowError />
    </div>
  );
};
export default Login;
